<script>
import { computed, reactive, toRefs } from 'vue'
import { useStore } from "vuex"

export default {
  name: "EmailInput",

  setup() {
    const store = useStore()

    const state = reactive({
      email: {
        value: '',
        error: computed(() => store.state['email'].error),
        passedRegex: computed(() => store.getters['email/passedRegex']),
        validated: computed(() => store.state.validated.email)
      }
    })

    const emailHandler = () => {
      store.commit('email/SET_EMAIL', state.email.value)
      store.commit('SET_VALIDATION', {validation: 'email', value: !state.email.error})

      if (state.email.passedRegex) {
        store.commit('SET_FIELD', { field: 'email', value: state.email.value })
        store.commit('email/SET_EMAIL_ERROR', false)
        return
      }

      if (!state.email.passedRegex) {
        //unset field in store
        store.commit('UNSET_FIELD', 'email')
        store.commit('email/SET_EMAIL_ERROR', true)
      }

      store.commit('SET_VALIDATION', {validation: 'email', value: !state.email.error})
    }

    return {
      emailHandler,
      ...toRefs(state)
    }
  }
}
</script>

<template>
  <div class="col">
    <label class="form-input-label">Email: </label>
    <input
      class="form-input"
      type="text"
      placeholder="john.doe@gmail.com"
      :class="{'input-error' : email.error}"
      v-model="email.value"
      @input="emailHandler"
    >
  </div>
</template>