<script>
import { useStore } from 'vuex'
import {toRefs, reactive, computed } from 'vue'
import { maska } from 'maska'

import { DatePicker } from 'v-calendar'

export default {
  name: "DateOfBirth",

  directives: {
    maska
  },

  components: {
    DatePicker
  },

  setup() {
    const store = useStore()

    const state = reactive({
      dateValue: '',
      error: computed(() => store.state['date_of_birth'].error),
      changedInput: computed(() => store.state['date_of_birth'].changedInput)
    })

    const dateHandler = () => {

      if (!state.changedInput) {
        store.commit('date_of_birth/SET_CHANGED_INPUT', true)
      }

      if (state.dateValue) {
        store.commit('date_of_birth/SET_DATE_OF_BIRTH', state.dateValue)
      }

      if (!state.dateValue && state.changedInput) {
        //set error
        store.commit('date_of_birth/SET_ERROR', true)
      }

      //set field using value from dob module
      if (!state.error && state.changedInput) {
        store.dispatch('date_of_birth/SET_COMPLETED_DOB')
      }

      store.commit('SET_VALIDATION', {validation: 'date_of_birth', value: !state.error})
    }

    return {
      dateHandler,
      ...toRefs(state)
    }
  }
}
</script>

<template>
  <div class="col">
    <label class="form-input-label">Date of Birth:</label>
    <DatePicker
      v-maska="'##/##/####'"
      color="orange"
      :min-date="new Date(1920, 1, 1)"
      :max-date="new Date()"
      :popover="{ visibility: 'click' }"
      mode="date"
      :update-on-input="false"
      v-model="dateValue"
      @click="dateHandler"
    >
      <template v-slot="{ inputValue, inputEvents }">
        <input
          class="form-input"
          :class="{'input-error' : error}"
          :value="inputValue"
          v-on="inputEvents"
          placeholder="mm/dd/yyyy"
          type="text"
          @change="dateHandler"
        >
      </template>
    </DatePicker>
  </div>
</template>