<script>
import {toRefs, reactive, computed} from 'vue'
import { useStore } from 'vuex'
import { setYearsRange } from "@/utils/helpers"

const years = setYearsRange(1992)

export default {
  name: "SelectVehicle",

  setup() {
    const store = useStore()

    const state = reactive({
      makeYear: {
        value: '0',
        selected: computed(() => store.state['vehicle'].carYearSelected)
      },
      carMake: {
        value: '0',
        selected: computed(() => store.state['vehicle'].carMakeSelected)
      },
      carModel: {
        value: '0',
        selected: false
      },
      carMakes: computed(() => store.getters['vehicle/getCarMakes']),
      carModels: computed(() => store.getters['vehicle/getCarModels']),
      vehicleValidated: computed(() => store.getters['vehicle/getCarValidated'])
    })

    const makeYearHandler = () => {
      store.commit('vehicle/SET_CAR_YEAR', {year: state.makeYear.value, selected: true})
      store.dispatch('vehicle/loadCarData')

      //if user changes make year, reset car make and car model, and reset vuex
      if (state.carMake.selected) {
        //car make reset
        state.carMake.value = '0'
        store.commit('vehicle/SET_CAR_MAKE', {make: state.carMake.value, selected: false})

        //car model reset
        state.carModel.value = '0'
        store.commit('vehicle/SET_CAR_MODEL', {model: state.carModel.value, selected: true})
      }

      store.commit('SET_FIELD', {field: 'year', value: state.makeYear.value})
      store.commit('SET_VALIDATION', {validation: 'vehicle', value: state.vehicleValidated})
    }

    const carMakeHandler = () => {
      store.commit('vehicle/SET_CAR_MAKE', {make: state.carMake.value, selected: true})

      //if user changes car make but model year is still selected, reset car model
      if (state.carMake.selected){
        state.carModel.value = '0'
        store.commit('vehicle/SET_CAR_MODEL', {model: state.carModel.value, selected: false})
      }

      store.commit('SET_FIELD', {field: 'make', value: state.carMake.value})
      store.commit('SET_VALIDATION', {validation: 'vehicle', value: state.vehicleValidated})
    }

    const carModelHandler = () => {
      state.carModel.selected = true
      store.commit('vehicle/SET_CAR_MODEL', {model: state.carModel.value, selected: true})

      store.commit('SET_FIELD', {field: 'model', value: state.carModel.value})
      store.commit('SET_VALIDATION', {validation: 'vehicle', value: state.vehicleValidated})
    }

    return {
      years,
      carMakeHandler,
      makeYearHandler,
      carModelHandler,
      ...toRefs(state)
    }
  }
}
</script>

<template>
  <div class="row">
    <div class="col">
      <label class="form-input-label">Model year: </label>
      <select
        id="car-model-year"
        name="car-model-year"
        class="form-select"
        @change="makeYearHandler"
        v-model="makeYear.value"
      >
        <option disabled value="0">Select Year</option>
        <option
          v-for="year in years"
          :value="year"
          :key="year"
        >
          {{year}}
        </option>
      </select>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <label class="form-input-label">Make: </label>
      <select
        name="car-make"
        id="car-make"
        class="form-select"
        :disabled="!makeYear.selected"
        v-model="carMake.value"
        @change="carMakeHandler"
      >
        <option disabled value="0">Select Make</option>
        <option
          v-for="car in carMakes"
          :key="car"
          :value="car"
        >
         {{car}}
        </option>
      </select>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <label class="form-input-label">Model: </label>
      <select
        name="car-model"
        id="car-model"
        class="form-select"
        :disabled="!carMake.selected"
        v-model="carModel.value"
        @change="carModelHandler"
      >
        <option disabled value="0">Select Model</option>
        <option
          v-for="car in carModels"
          :key="car.model"
          :value="car.model"
        >
          {{car.model}}
        </option>
      </select>
    </div>
  </div>
</template>

<style scoped>

</style>