<script>
import { computed, reactive, toRefs } from 'vue'
import { useStore } from "vuex"

export default {
  name: "NameInput",

  setup() {
    const store = useStore()

    const state = reactive({
      firstName: {
        value: '',
        error: computed(() => store.state['names'].firstName.error),
      },
      lastName: {
        value: '',
        error: computed(() => store.state['names'].lastName.error)
      }
    })

    const firstNameHandler = () => {
      store.commit('names/SET_NAME', {name: 'firstName', nameValue: state.firstName.value})

      if (!state.firstName.error) {
        store.commit('SET_FIELD', {field: 'first_name', value: state.firstName.value})
      }

      if (state.firstName.error) {
        store.commit('UNSET_FIELD', 'first_name')
      }

      store.commit('SET_VALIDATION', {validation: 'firstName', value: !state.firstName.error})
    }

    const lastNameHandler = () => {
      store.commit('names/SET_NAME', {name: 'lastName', nameValue: state.lastName.value})

      if (!state.lastName.error) {
        store.commit('SET_FIELD', {field: 'last_name', value: state.lastName.value})
      }

      if (state.lastName.error) {
        store.commit('UNSET_FIELD', 'last_name')
      }

      store.commit('SET_VALIDATION', {validation: 'lastName', value: !state.lastName.error})
    }

    return {
      firstNameHandler,
      lastNameHandler,
      ...toRefs(state)
    }
  }
}
</script>

<template>
  <div class="col">
    <label class="form-input-label">First Name: </label>
    <input
      class="form-input"
      type="text"
      placeholder="John"
      :class="{'input-error' : firstName.error}"
      v-model="firstName.value"
      @input="firstNameHandler"
    >
  </div>
  <div class="col">
    <label class="form-input-label">Last Name: </label>
    <input
      class="form-input"
      type="text"
      placeholder="Doe"
      :class="{'input-error' : lastName.error}"
      v-model="lastName.value"
      @input="lastNameHandler"
    >
  </div>
</template>