<script>
export default {
  name: "Info",

  props: {
    description: {
      type: String,
      required: true
    }
  }
}
</script>

<template>
  <div class="info-container">
    <p class="description-text">
      {{description}}
    </p>
  </div>
</template>