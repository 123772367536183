<script>
import { computed, reactive, toRefs } from 'vue'
import { useStore } from "vuex"
import { maska } from "maska"

export default {
  name: "PhoneInput",

  directives: {
    maska
  },

  setup() {
    const store = useStore()

    const state = reactive({
      phone: {
        value: '',
        error: computed(() => store.state['phone'].error)
      }
    })

    const phoneHandler = () => {
      store.commit('phone/SET_PHONE', state.phone.value)
      store.commit('SET_VALIDATION', {validation: 'phone', value: !state.phone.error})

      if (!state.phone.error) {
        store.commit('SET_FIELD', {field: 'phone', value: state.phone.value.replace(/[()\-\s]+/g, "")})
        store.commit('phone/SET_PHONE_ERROR', false)
        return
      }

      //if phone exists in store fields and phone error exists, unset the field from store
      if (store.state.fields['phone'] && state.phone.error) {
        store.commit('UNSET_FIELD', 'phone')
        store.commit('phone/SET_PHONE_ERROR', true)
      }
    }

    return {
      phoneHandler,
      ...toRefs(state)
    }
  }
}
</script>

<template>
  <div class="col">
    <label class="form-input-label">Phone: </label>
    <input
      class="form-input"
      type="text"
      placeholder="(888) 412-1858"
      v-maska="'(###) ###-####'"
      :class="{'input-error' : phone.error}"
      v-model="phone.value"
      @input="phoneHandler"
    >
  </div>
</template>