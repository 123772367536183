<script>
import {reactive, toRefs, computed, ref, onMounted} from 'vue'
import { useStore } from 'vuex'

export default {
  name: "Address",

  setup() {
    const store = useStore()

    const state = reactive({
      error: computed(() => store.state['address'].error),
      completedAutocomplete: computed(() => store.state['address'].completedGoogleMapsAutoComplete)
    })

    const addressInput = ref(null)

    onMounted(() => {
      store.dispatch('address/initGoogleMapsAutocomplete', addressInput.value)
      store.dispatch('address/getAddressInfo')
    })

    const addressHandler = () => {
      store.commit('address/SET_ADDRESS_CHANGE_ERROR')

      //if user changes address
      if (state.completedAutocomplete) {
        //reset address fields
        store.commit('address/RESET_ADDRESS')
        //reset central address validation
        store.commit('SET_VALIDATION', {validation: 'address', value: false}, {root: true})

        const fields = [
          'address',
          'zip',
          'state',
          'county',
          'city'
        ]

        //unset fields in index
        store.commit('UNSET_FIELDS', fields)
      }
    }

    return {
      addressHandler,
      addressInput,
      ...toRefs(state)
    }
  }
}
</script>

<template>
  <div class="col">
    <label class="form-input-label">Address: </label>
    <input
      class="form-input"
      type="text"
      placeholder="123 Main Ave"
      :class="{'input-error' : error}"
      @input="addressHandler"
      ref="addressInput"
    >
  </div>
</template>