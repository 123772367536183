<script>
import { reactive, toRefs, computed, onMounted, onBeforeMount } from 'vue'
import { useStore } from 'vuex'

import NameInput from "@/components/shared/contact/NameInput"
import EmailInput from "@/components/shared/contact/EmailInput"
import PhoneInput from "@/components/shared/contact/PhoneInput"
import DateOfBirth from "@/components/shared/contact/DateOfBirth"
import Address from "@/components/shared/contact/Address"
import SelectVehicle from "@/components/shared/SelectVehicle"
import TCPACompliance from "@/components/shared/TCPACompliance"

export default {
  name: "Form",

  components: {
    DateOfBirth,
    NameInput,
    EmailInput,
    PhoneInput,
    Address,
    SelectVehicle,
    TCPACompliance
  },

  setup() {
    const store = useStore()

    const state = reactive({
      loading: computed(() => store.state.loading),
      submitBtnText: computed(() => store.state.loading ? 'Loading...' : 'Submit'),
      allValidated: computed(() => store.getters.allValidated)
    })

    const submit = () => {
      store.commit('SET_LOADING', true)
      if (!state.allValidated) {
        store.commit('SET_LOADING', false)
        return
      }
      store.dispatch('sendDataToLp')
    }

    onBeforeMount(() => {
      store.commit('SET_LOADING', true)
      store.dispatch('init')
    })

    onMounted(() => {
      store.commit('SET_LOADING', false)
    })

    return {
      submit,
      ...toRefs(state)
    }
  }
}
</script>

<template>
  <!--empty for is for trusted form to work-->
  <form></form>

  <div
    v-if="loading"
    class="disabled-overlay"
  >
    <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
  </div>

  <div class="main-form-container">
    <div class="row flex-center">
      <h3 class="header-title-bold">Vehicle Information</h3>
    </div>
    <hr class="divider" style="margin-bottom: 10px"/>
    <SelectVehicle />

    <hr class="divider" style="margin-top: 10px"/>
    <div class="row flex-center">
      <h3 class="header-title-bold">Please fill out your contact information</h3>
    </div>
    <hr class="divider"/>

    <div class="row">
      <NameInput />
    </div>
    <div class="row">
      <EmailInput />
      <PhoneInput />
    </div>

    <div class="row">
      <DateOfBirth />
      <Address />
    </div>

    <div class="row flex-center">
      <div class="submit-button-container">
        <button
          class="submit-button"
          @click="submit"
          :disabled="!allValidated || loading"
        >
          {{ submitBtnText }}
        </button>
      </div>
    </div>
    <div class="row flex-center tcpa-compliance">
      <TCPACompliance/>
    </div>
  </div>
</template>