<script>
export default {
  name: "TCPACompliance"
}
</script>

<template>
  <div class="tcpa-flow-container">
    <label for="">
      <input type="hidden" id="leadid_tcpa_disclosure"/>
      <p class="compliance-text">
        By clicking "Submit", I confirm I have read and agreed to this website's <a href="/terms-of-use" target="_blank">Terms of Service</a> and
        <a href="/privacy-policy" target="_blank">Privacy Policy</a> and provide my express written consent via electronic signature authorizing
        Zen Auto Insurance and <a href="/partners" target="_blank">one or more law firms, their agents and marketing partners</a> to contact me about
        my case and other related legal products and services to the number and email address I provided (including any wireless number). I further
        expressly consent to receive telemarketing emails, calls, text messages, pre-recorded messages, and artificial voice messages via an
        autodialed phone system, even if my telephone number is a mobile number that is currently listed on any state, federal or corporate
        “Do Not Call” list. I understand that my consent is not a condition of purchase of any goods or services and that standard message and
        data rates may apply. For California residents see <a href="/privacy-policy/#ca-privacy-rights" target="_blank">here</a>.
      </p>
    </label>
  </div>
</template>