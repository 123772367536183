<script>
import Info from "@/components/shared/Info"
import Form from "@/components/shared/Form"

const description =
  "Stay in a state of Zen while we find you the best auto insurance rates! Simply fill out the form with your information and we will do all the work for you. Take a deep breath and save on your auto insurance today!"

export default {
  name: "AutoInsuranceForm",
  components: {
    Info,
    Form
  },

  setup() {


    return {
      description
    }
  }
}
</script>

<template>
  <div class="car-wrapper">
    <div class="blur-bg"></div>
    <div class="hero">
      <div class="box">
        <Info :description="description"/>
      </div>

      <div class="box">
        <Form />
      </div>
    </div>
  </div>
</template>